import DefaultField from "./default";
import Select from "./select";
import { COMMON_WORDS_DAY, COMMON_WORDS_MONTH, COMMON_WORDS_YEAR, trans } from "../../../../translator";

export default class DateSelect extends DefaultField {

  static fieldType = "dateSelect";

  fieldsDisplayed = ['day', 'month', 'year'];

  inversed = true;

  constructor(key, value, config = {}, manager) {

    super(key, value, config, manager);

    if (config?.fieldsDisplayed) {
      this.fieldsDisplayed = config.fieldsDisplayed;
    }

    if (typeof config?.inversed !== "undefined") {
      this.inversed = config.inversed;
    }

  }

  getInput() {

    let valueDate = this.value.rawValue;

    if (!valueDate) {
      valueDate = null;
    }

    if (typeof valueDate === "string") {
      valueDate = new Date(valueDate);
    }

    return `
        <div class="form-flex-row">
            ${this.fieldsDisplayed.includes('day') ? this.getDayInput(valueDate) : ''}
            ${this.fieldsDisplayed.includes('month') ? this.getMonthInput(valueDate) : ''}
            ${this.fieldsDisplayed.includes('year') ? this.getYearInput(valueDate) : ''}
        </div>`;
  }

  getDayInput(value) {
    return this.getSelectInput(
      trans(COMMON_WORDS_DAY, {}, 'common'),
      "day",
      value?.getDate() ?? "",
      1,
      31
    );
  }

  getMonthInput(value) {
    return this.getSelectInput(
      trans(COMMON_WORDS_MONTH, {}, 'common'),
      "month",
      (value?.getMonth() !== null) ? (value?.getMonth() + 1) : "",
      1,
      12);
  }

  getYearInput(value) {

    const today = new Date();

    return this.getSelectInput(
      trans(COMMON_WORDS_YEAR, {}, 'common'),
      "year",
      value?.getFullYear() ?? "",
      today.getFullYear() - 130, today.getFullYear(),
      this.inversed);
  }


  getSelectInput(placeholder, key, value, minNumber, maxNumber, inversed) {

    const select = new Select(`${this.key}.${key}`,
      { label: placeholder, value: value, rawValue: value },
      this.config,
      this.manager);

    select.placeholder = placeholder;
    const options = this.createOptions(minNumber, maxNumber);

    select.options = options;
    if(inversed) {
      select.optionsOrder = Object.keys(options).reverse();
    } else {
      select.optionsOrder = Object.keys(options);
    }
    return select.getInput();


  }

  createOptions(min, max) {
    const options = {};

    for (let i = min; i <= max; i++) {
      options[i.toString()] = i < 10 ? "0" + i : i;
    }

    return options;
  }


}
