import AddressField from "./AddressField";
import { ts } from "../../common";
import BaseRepeaterField from "./BaseRepeaterField";

export default class AddressesField extends BaseRepeaterField {

  static fieldType = "addressesField";

  toRegister = [];

  type;


  constructor(key, value, config, manager) {

    super(key,value,config,manager);

    this.type = config.type;

    this.button = ts(`profile.addreses.${this.type}.button`);

  }


  getSubFields(val, index) {

    const address = new AddressField(`${this.key}.${index}`, { rawValue : val }, this.config, this.manager);

    this.toRegister.push(address);

    return address.display();

  }


  getRow(val, index) {
    const $template = $(`<div class="form-flex-row" >
           ${this.getDeleteButton(!this.allowEmpty && index === 0)}
        </div>`);

    $template.prepend(this.getSubFields(val,index));

    return $template;

  }



  registerEvents() {
    super.registerEvents();

    this.toRegister.forEach((input) => {
      input.registerEvents();
    });

    this.toRegister = [];

  }



}
