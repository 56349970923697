import { initTelInput } from "../../form-utils";
import BaseRepeaterField from "./BaseRepeaterField";
import { COMMON_WORDS_NUMBER, trans, USER_ACTIONS_ADD_PHONE, USER_FIELDS_PHONES } from "../../../../translator";

export default class PhonesField extends BaseRepeaterField {

  label = trans(USER_FIELDS_PHONES, {}, 'user');
  button = trans(USER_ACTIONS_ADD_PHONE, {}, 'user');

  static fieldType = "phonesField";

  getSubFields(val, index) {
    return `
            ${this.getSubField(val, index, "id", "Type", { type: "hidden" })} 
            <div class="form-group">       
                ${this.getSubField(val, index, "label", "Type", { type: "text" })}   
            </div>
            <div class="form-group">
                ${this.getSubField(val, index, "number", 
    trans(COMMON_WORDS_NUMBER, {}, 'common'), { type: "tel" })}
        </div>
    `;
  }

  registerEvents() {
    super.registerEvents();

    this.$template.find('input[type="tel"]').each((key, elem) => {
      initTelInput($(elem));
    });

  }


}
