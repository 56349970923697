import AddressField from "./fields/AddressField";
import { parseId, ts, ts_exists } from "../common";
import PatientUpdater from "./patient-updater";
import { MEDICAL_RECORD_FIELD } from "../utils";
import RadioField from "./fields/radioField";
import RepeaterField from "./fields/RepeaterField";
import AutocompleteField from "./fields/AutocompleteField";
import DateField from "./fields/dateField";
import Textarea from "./fields/textarea";
import {
  COMMON_WORDS_NO,
  COMMON_WORDS_YES,
  DOCUMENTS_ACTIONS_SELECT,
  trans,
  USER_FIELDS_PHONE
} from "../../../translator";


export default class DataTransformer
{


  static transformCustomField(field,patient) {

    const data = {
      label: field.name,
      suffix: field.unit,
      displayIfEmpty: true,
      readonly: field.readOnly,
      value: field.data,
      title: field.title,
      transformer: "fieldTransfomer",
      isInSynopsis: field.isInSynopsis,
      dynamic: field.dynamic,
      fieldProps: {}
    };

    if (field.required) {
      data.fieldProps.required = true;
    }


    switch (field.fieldType) {
      case MEDICAL_RECORD_FIELD.TYPE.TYPE_BOOLEAN: {
        data.field = RadioField.fieldType;
        data.options = {
          true: trans(COMMON_WORDS_YES, {}, 'common'),
          false: trans(COMMON_WORDS_NO, {}, 'common')
        };
        break;
      }
      case MEDICAL_RECORD_FIELD.TYPE.TYPE_CHOICE_SINGLE: {
        data.field = RadioField.fieldType;
        data.options = field.choices;
        data.optionsOrder = field.choicesOrder;

        if (field.customValueLabel) {
          data.customValueLabel = field.customValueLabel;
        }

        data.placeholder = trans(DOCUMENTS_ACTIONS_SELECT, {}, 'documents');


        break;
      }

      case MEDICAL_RECORD_FIELD.TYPE.TYPE_REPEATER: {
        data.field = RepeaterField.fieldType;
        data.button = field.addMoreLabel;
        data.transformer = "repeaterTransformer";

        data.fieldProps.allowEmpty = true;

        data.children = [];

        field.children.forEach((row) => {
          const rowValues = {};
          row.forEach((subField) => {
            rowValues[subField.canonical] = DataTransformer.transformCustomField(subField,patient);
          });
          data.children.push(rowValues);
        });

        break;
      }

      case MEDICAL_RECORD_FIELD.TYPE.TYPE_CHOICE_MULTIPLE: {

        data.field = AutocompleteField.fieldType;
        //  data.options = field.choices;
        data.fieldProps.url = 'api_medical_record_options_get_choices_collection';
        data.fieldProps.params = {
          patient: patient.id,
          id: parseId(field['@id'])
        };
        data.fieldProps.parser = "medicalField";
        break;
      }

      case MEDICAL_RECORD_FIELD.TYPE.TYPE_DATE: {
        data.field = DateField.fieldType;
        break;
      }
      case MEDICAL_RECORD_FIELD.TYPE.TYPE_NUMBER: {
        data.fieldProps.type = "number";
        data.fieldProps.step = field.step ?? undefined;
        data.fieldProps.min = field.min ?? undefined;
        data.fieldProps.max = field.max ?? undefined;
        break;
      }

      case MEDICAL_RECORD_FIELD.TYPE.TYPE_TEXTAREA: {
        data.field = Textarea.fieldType;
      }

    }

    return data;

  }


  static transform(value, key, fieldConfig) {

    const config = { ...fieldConfig };

    const { transformer = "defaultTransformer" } = config;

    if (DataTransformer[transformer]) {
      return DataTransformer[transformer](value, key, config);
    } else {
      console.warn(`Transformer ${transformer} not found, using default`);
      return DataTransformer.defaultTransformer(value, key, config);
    }
  }



  static addressesTransformer(value, key, config) {

    const rawValue = value.length ? value[0] : null;

    if (!rawValue) {
      return DataTransformer.defaultTransformer(null, key, config);
    }

    return DataTransformer.addressTransformer(rawValue, key, config);

  }


  static externalIdTransformer(value,key,config) {

    // eslint-disable-next-line no-unused-vars
    const parts = key.split('.');

    const provider = parts[parts.length -1];

    value = value[provider] ?? '';

    config['label'] = ts('external_id_number',{
      '{{ provider }}' : provider
    });

    return DataTransformer.defaultTransformer(value,key,config);

  }

  static parentTransformer(value,key,config) {

    const rawValue = value;

    if(!value) {
      return DataTransformer.defaultTransformer(null, key, config);
    }

    if(value.user.rights.update) {
      const url = Routing.generate("app_patient_user_profile",{ id : parseId(value.user['@id']) });
      value = `<a href="${url}" class='small' >${value.user.fullName}</a>`;
    } else {
      value = value.user.fullName;
    }

    return DataTransformer.defaultTransformer(value, key, config,rawValue);

  }



  static repeaterTransformer(value, key, config) {

    const values = [];
    const rawValues = [];

    config.children.forEach((row) => {

      const rowValues = {};
      const rowRowValues = {};

      Object.keys(row).forEach((fieldKey) => {

        const child = row[fieldKey];

        let displayedValue = child.value?.displayValue ?? '';
        if (displayedValue && child.suffix) {
          displayedValue += ' ' + child.suffix;
        }

        rowValues[fieldKey] = {
          label: child.label,
          value: displayedValue,
          rawValue: child.value?.value,
        };
        rowRowValues[fieldKey] = child.value;
      });

      values.push(rowValues);
      rawValues.push(rowRowValues);
    });

    return {
      key: key,
      label: config.label,
      value: values,
      rawValue: rawValues
    };

  }


  static defaultTransformer(value, key, config, rawValue) {

    rawValue = rawValue !== undefined ? rawValue : value;

    if (value && config?.suffix) {
      value = `${value} ${config.suffix}`;
    }

    const translationKey = `medical_records.${key}`;

    if (Array.isArray(value)) {
      value = value.join(", ");
    }

    if (value instanceof Date) {
      console.warn("Date value should use the dateTransformer");
      value = value.toLocaleDateString();
    }

    if (typeof value === "undefined" || value === null) {
      value = "";
    }

    return {
      label: config.label ? config.label : ts_exists(translationKey) ? ts(translationKey) : key,
      value: value,
      rawValue: rawValue,
      key: key
    };

  }



  static booleanTransformer(value, key, config) {

    const rawValue = value;

    if (value === "true" || true === value) {
      value = trans(COMMON_WORDS_YES, {}, 'common');
    } else if (value === "false" || false === value) {
      value = trans(COMMON_WORDS_NO, {}, 'common');
    } else {
      value = null;
    }


    return this.defaultTransformer(value, key, config, rawValue);
  }


  static ageTransformer(value, key, config) {

    if (value) {
      value = new Date(value.substr(0, 10));
    }

    return DataTransformer.defaultTransformer(PatientUpdater.formatAgeText(value, config.sex), key, config, value);

  }

  static dateTransformer(value, key, config) {

    let rawValue;

    if (value) {
      rawValue = new Date(value.substr(0, 10));
      value = rawValue.toLocaleDateString();
    }

    return DataTransformer.defaultTransformer(value, key, config, rawValue);

  }

  static fieldTransfomer(value, key, config) {

    return DataTransformer.defaultTransformer(value?.displayValue, key, config, value?.value);
  }


  static phoneTransformer(value, key) {

    const data = [];


    value.forEach((phone, index) => {

      let number;

      try {
        number = intlTelInputUtils.formatNumber(phone.number, null, intlTelInputUtils.numberFormat.INTERNATIONAL);
      } catch (e) {
        number = phone.number;
      }

      data.push({
        label: phone.label ?? trans(USER_FIELDS_PHONE, {}, 'common'),
        value: number,
        rawValue: phone,
        key: `${key}.${index}`
      });

    });

    return data;

  }


  static addressTransformer(value, key, config) {

    const rawValue = value;

    if (!rawValue) {
      return DataTransformer.defaultTransformer(null, key, config);
    }

    return DataTransformer.defaultTransformer(AddressField.stringifyAddress(rawValue), key, config, rawValue);

  }


  static handleCustomFieldValues(value, type) {

    if(type !== RepeaterField.fieldType) {
      return DataTransformer.handleAutocompleteValue(value);
    }

    value = Object.values(value);
    // Per row
    Object.keys(value).forEach((rowKey) => {
      // Per field
      Object.keys(value[rowKey]).forEach((key) => {
        value[rowKey][key] = DataTransformer.handleAutocompleteValue(value[rowKey][key]);
      });
    });

    return value;

  }


  /**
     *
     * This is used when fetching the data to send back to the server.
     * Some fields are TagsAutocompleter and return values that needs to be processed before being sent to the back-end
     *
     * @param value
     * @returns {*}
     */
  static handleAutocompleteValue(value) {

    // Handle values of autocomplete fields
    if (!Array.isArray(value)) {
      return value;
    }


    return value.map((val) => {
      return val?.id ? val.id : val;
    });

  }


}