import DefaultField from "./default";
import { autosizeTextArea } from "../../common";

export default class Textarea extends DefaultField {

  static fieldType = "textarea";

  autoResize = false;

  constructor(key, value, config = {}, manager) {
    super(key, value, config, manager);

    if(this.config.autoResize) {
      this.autoResize = true;
    }

  }

  getInput() {
    return `<textarea ${this.getAttributes()}>${this.value.rawValue ?? ""}</textarea>`;
  }

  registerEvents() {
    super.registerEvents();

    if(this.autoResize) {
      autosizeTextArea(this.$template.find('textarea'));
    }
  }

}
