import DefaultField from "../fields/default";
import ConstantSelect from "../fields/constantSelect";
import DateSelect from "../fields/dateSelect";
import AutocompleteField from "../fields/AutocompleteField";
import DateField from "../fields/dateField";
import RadioField from "../fields/radioField";
import PhonesField from "../fields/PhonesField";
import AddressField from "../fields/AddressField";
import PatientProfileEditableSectionManager from "./PatientProfileEditableSectionManager";
import { HTTP, MEDICAL_RECORD_SECTIONS } from "../../utils";
import { GET, handleViolations, parseId, POST, resetErrors } from "../../common";
import RepeaterField from "../fields/RepeaterField";
import Textarea from "../fields/textarea";
import EditableSectionManager from "./EditableSectionManager";
import DataTransformer from "../DataTransformer";
import FileInput from "../fields/fileInput";

export default class AbstractFieldRowDataManager extends PatientProfileEditableSectionManager {


  fields = {};

  formFields = {};

  dynamicallyUpdated = false;
  dynamic = false;

  set disabled(disabled) {
    Object.keys(this.formFields).forEach((key) => {
      this.formFields[key].disabled = disabled;
    });
  }


  async init() {
    if (this.isInit) {
      return;
    }

    if (this.section.sectionType === MEDICAL_RECORD_SECTIONS.TYPE.TYPE_FIELD_LIST) {
      await this.initCustomFields();
    }

    this.dynamicallyUpdated = this.section.dynamicallyUpdated;

    await super.init();

    this.$template.find('.loader').hide();
  }

  fillTemplate() {
    super.fillTemplate();

    this.$template.find('.text-content').find('.field,.section_title').remove();

    Object.keys(this.fields).forEach((key) => {

      if (this.fields[key].writeOnly) {
        return;
      }

      const value = this.getValue(key, this.fields[key]);

      if (this.fields[key].dynamic) {
        this.dynamic = true;
      }

      this.displayValue(value, this.fields[key]);

    });

  }

  insertTemplate() {

    if (this.fields["field.allergies"]) {
      this.fields["field.allergies"].className =
                this.profile.allergies && this.profile.allergies.length ? "danger" : "";
    }

    super.insertTemplate();
  }

  displayValue(value, config) {

    const { displayIfEmpty, className } = config;

    if (Array.isArray(value)) {

      value.forEach((item) => {
        this.displayValue(item, config);
      });
      return;
    }

    if (!displayIfEmpty && !value.value) {
      return;
    }

    this.addValueRow(value, className, config);

  }


  addValueRow(value, className, config) {

    let $template;
    const fieldName = value.key.replace(/\./g, "_");

    if (Array.isArray(value.value)) {
      $template = this.getMultiRowTemplate(value, className, fieldName);
    } else {
      $template = this.getRowTemplate(value, className, fieldName);
    }


    if (config.title) {
      $template = `<strong class="section_title" >${config.title}</strong>` + $template;
    }

    this.$template.find('.text-content .fields').append($template);

  }


  getMultiRowTemplate(value, className, fieldName) {

    let $template;
    $template = `<div class="field repeater-field-value ${className ?? ""} ${fieldName}">`;
    $template += `<strong>${value.label} :</strong>`;
    $template += `<ul>`;


    value.value.forEach((row) => {
      let rowTemplate = '';
      Object.keys(row).forEach((fieldKey) => {
        const field = row[fieldKey];
        if (field.value) {
          rowTemplate += '<span class="repeater-block">';
          rowTemplate += `<strong>${field.label} :</strong> `;
          rowTemplate += `<span ${this.calculateRowTitle(field.value)} >${field.value}</span>`;
          rowTemplate += '</span>';
        }
      });

      if (rowTemplate) {
        $template += `<li>${rowTemplate}</li>`;
      }
    });

    $template += `</ul>`;

    $template += `</div>`;

    return $template;

  }


  getRowTemplate(value, className, fieldName) {
    return `<div class="field ${className ?? ""} ${fieldName}">
                    <strong>${value.label} :</strong>
                    <span title="${this.calculateRowTitle(value.value)}">${value.value}</span>
                </div>`;

  }

  calculateRowTitle(value) {
    return typeof value === "string" && value.indexOf("</") === -1 ? value.replace('<br/>', "") : "";
  }


  async onChange() {

    this.disabled = true;

    if (window.showLoader) {
      this.$loader.show();
    }

    let value = this.getFormValue();

    const fields = await POST(Routing.generate('api_medical_record_fields_post_patient_section_collection', {
      id: parseId(this.profile['@id']),
      section: this.section.canonical,
    }), value.field);

    this.reloadFields(fields);

    this.disabled = false;

    this.$loader.hide();
  }


  reloadFields(fields) {

    const existing = [];

    let previousField;

    fields['hydra:member'].forEach((field) => {

      const id = `field.${field.canonical}`;

      const transformedField = DataTransformer.transformCustomField(field, this.profile);

      const currentField = this.formFields[id];

      if (!currentField) {

        const parent = previousField ?
          this.$form.find(`[data-field="${previousField.replace(".", "_")}"]`).closest('.form-group') : undefined;

        this.addField(id, transformedField, parent);
      } else {

        const value = this.getValue(id, transformedField);
        currentField.update(transformedField, value);
      }

      previousField = id;

      existing.push(id);

    });

    Object.keys(this.formFields).forEach((key) => {

      if (existing.includes(key)) {
        return;
      }

      const field = this.formFields[key];

      field.remove();

      delete this.formFields[key];
      delete this.fields[key];

    });

  }


  async save(profile) {


    this.dispatchEvent(new CustomEvent('submit'));

    resetErrors(this.$form);

    if (!profile.fields) {
      return super.save(profile);
    }

    // If no user, no need to save profile
    if (!profile.user) {
      const fields = await this.saveFields(profile.fields);
      this.parseCustomFieldsResponse(fields);

      this.dispatchEvent(new CustomEvent('save', {
        detail: {
          fields,
        }
      }));

      return;
    }

    const promises = [];

    promises.push(this.saveFields(profile.fields));
    promises.push(super.save(profile));

    try {
      const [fields, profileData] = await Promise.all(promises);

      this.parseCustomFieldsResponse(fields);

      this.dispatchEvent(new CustomEvent('save', {
        detail: {
          fields,
          profile: profileData
        }
      }));


      return profileData;
    } catch (e) {
      this.dispatchEvent(new CustomEvent('error'));
      throw e;

    }
  }


  async saveFields(data) {
    try {
      return await POST(Routing.generate('api_patients_medical_record_tabs_bulk', { id: this.profile.id }), data);
    } catch (e) {

      if (e.status === HTTP.STATUS_CODE.BAD_REQUEST) {
        this.handleFieldViolations(data, e);
      }

      throw e;

    }
  }


  getValue(key, fieldConfig) {

    const config = { ...fieldConfig };

    const [object, field] = EditableSectionManager.splitKey(key);

    let value;

    switch (object) {
      case "user" :
        value = this.profile.user[field];
        break;
      case "profile" :
        value = this.profile[field];
        break;
      default :
        value = { ...config.value };
    }


    // Because this one is annoying
    if (config.transformer === "constantTransformer") {
      return this.constantTransformer(value, key, config);
    }

    return DataTransformer.transform(value, key, config);

  }


  constantTransformer(value, key, config) {

    const rawValue = value;

    const { data, label } = this.getConstantData(key);

    if (value) {
      value = data[value] ?? value;
    }

    return DataTransformer.defaultTransformer(value, label, config, rawValue);
  }


  getSectionData() {
    return $(`#section-field-rows`).html();
  }

  splitKey(key) {
    return key.split('.');
  }


  async fillForm() {

    this.formFields = {};

    super.fillForm();

    Object.keys(this.fields).forEach((key) => {
      this.addField(key, this.fields[key]);
    });


  }


  addField(key, field, parent) {

    if (field.readonly) {
      return;
    }
    const $field = this.getField(key, field);

    if ($field) {

      if (parent && parent.length) {
        parent.after($field.display());
      } else {
        this.$drawer.find('.fields').append($field.display());
      }

      $field.init();
      $field.registerEvents();

      $field.addEventListener("change", (evt) => {
        if (evt.detail.isKeyUp) {
          return;
        }

        if (evt.detail.isDynamicField) {
          this.onChange();
        }
      });

      this.formFields[key] = $field;
    }
  }


  getField(key, config) {

    const value = this.getValue(key, config);

    return AbstractFieldRowDataManager.buildField(key, value, config, this);

  }


  static buildField(key, value, config, $this) {

    const { field: fieldType } = config;

    switch (fieldType) {
      case ConstantSelect.fieldType:
        return new ConstantSelect(key, value, config, $this);
      case AutocompleteField.fieldType:
        return new AutocompleteField(key, value, config, $this);
      case DateSelect.fieldType:
        return new DateSelect(key, value, config, $this);
      case DateField.fieldType:
        return new DateField(key, value, config, $this);
      case Textarea.fieldType:
        return new Textarea(key, value, config, $this);
      case RadioField.fieldType:
        return new RadioField(key, value, config, $this);
      case FileInput.fieldType:
        return new FileInput(key, value, config, $this);
      case PhonesField.fieldType:
        return new PhonesField(key, value, config, $this);
      case AddressField.fieldType:
        return new AddressField(key, value, config, $this);
      case RepeaterField.fieldType:
        return new RepeaterField(key, value, config, $this);
      default:
        return new DefaultField(key, value, config, $this);
    }
  }


  getCustomFieldValue(key) {
    return this.section.fields.find((field) => field.canonical === key)?.data;
  }

  async initCustomFields() {

    this.insertTemplate();

    this.$template.find('.loader').show();

    this.formFields = {};

    const fields = await GET(Routing.generate('api_medical_record_fields_get_patient_section_collection', {
      id: parseId(this.profile['@id']),
      section: this.section.canonical,
    }));

    fields['hydra:member'].forEach((field) => {
      this.fields[`field.${field.canonical}`] = DataTransformer.transformCustomField(field, this.profile);
    });

  }


  getFormValue() {
    const data = super.getFormValue();

    return this.handleCustomFieldValues(data);

  }

  handleCustomFieldValues(data) {

    if (!data.field) {
      return data;
    }

    Object.keys(data.field).forEach((field) => {
      const type = this.fields[`field.${field}`]?.field;
      data.field[field] = DataTransformer.handleCustomFieldValues(data.field[field], type);
    });

    return data;
  }


  parseCustomFieldsResponse(response) {

    this.fields = {};

    response.forEach((field) => {
      this.fields[`field.${field.canonical}`] = DataTransformer.transformCustomField(field, this.profile);

      // This reloads the field's data in the synopsis
      const $field = $(`#synopsis-field-${field.canonical}`);
      if ($field.length) {
        const displayValue = field.unit ? `${field.data.displayValue} ${field.unit}` : field.data.displayValue;
        // This is dégeulasse but I don't have another option
        $field.html(field.canonical === 'allergies' ? displayValue : `${field.name} : ${displayValue}`);
      }


    });
  }


  handleFieldViolations(data, e) {
    if (e.status !== HTTP.STATUS_CODE.BAD_REQUEST) {
      return;
    }

    if (!e.responseJSON.violations) {
      return;
    }

    handleViolations(e, "field", this.$form, [], true);
  }

  async refresh() {
    if (!this.isInit) {
      return;
    }


    this.formFields = {};
    this.fields = {};
    await this.initCustomFields();
    this.insertTemplate();

    this.$template.find('.loader').hide();

  }


}
