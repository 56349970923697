export default class SectionManager extends EventTarget {


  tab;

  isInit = false;
  $template;

  get loader() {
    return this.$template.find('.loader');
  }

  $header = $('#section-header');

  $container;

  section;

  get profile() {
    return this.manager.profile;
  }

  set profile(profile) {
    this.manager.profile = profile;
  }

  manager;

  constructor(section, $container, manager) {

    super();

    this.section = section;
    this.$container = $container;
    this.manager = manager;
  }


  async init() {
    if(this.isInit) {
      return;
    }

    this.insertTemplate();

    this.isInit = true;

    this.registerEvents();
  }


  insertTemplate() {

    this.fillTemplate();

    if (this.$container.find(`#${this.getSectionId()}`).length === 0) {
      this.$container.append(this.$template);
    }
  }


  fillTemplate() {

    if (this.$template) {
      return this.$template;
    }

    const $template = $(`<div class="v2__block default-block">${this.$header.html()}${this.getSectionData()}</div>`);

    this.fillHeader($template);

    this.$template = $template;

  }

  fillHeader($template)
  {

    $template.find('.h_content').html(this.section.name);
    $template.find('.h_count').html(this.section.nbFilledField);
    $template.attr('id', this.getSectionId());
    $template.find('.h_icon').addClass("material-icons").html(this.section.icon);
    $template.find('.h_icon').css("color", this.section.color);
    $template.addClass(`span-${this.section.size}`);

    const inputId = `${this.section.canonical}_toggler`;

    const $input = $template.find('.toggle_input');

    $template.find('label.v2__block_header').attr('for',inputId);
    $input.attr('id',inputId);

    $input.on('change',() => {

      if(!$input.prop('checked')) {
        this.init();
      }
    });


  }


  registerEvents() {

    const $toggle = this.$template.find('.toggle_input');

    $toggle.on('change',(e) => {

      this.$template.find('.h_count')
        .toggle(e.currentTarget.checked &&
            this.$template.find('.v2__block_header .actions').is(':visible'));

    });

    $toggle.trigger('change');

  }

  lightInit() {
    this.insertTemplate();
    this.loader.hide();
  }


  getRowId(object) {
    return `${this.section.canonical}-${object.id}`;
  }


  getSectionData() {
    return $(`#section-${this.section.sectionType}`).html();
  }


  getSectionId() {
    return `section-${this.section.canonical}`;
  }

  async refresh(){
    this.insertTemplate();

    this.manager.managers.general_data?.displayTreatments();
  }


}
