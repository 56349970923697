import DefaultField from "./default";
import { createDatePicker } from "../../common";
import { fillField } from "../../form-utils";

export default class DateField extends DefaultField {

  static fieldType = "dateField";

  options = {};


  registerEvents() {
    super.registerEvents();

    const $input = this.$template.find('input');

    createDatePicker($input,{
      yearRange: "-75:+3"
    });
    fillField($input,$input.val());
  }

}
