import DefaultField from "./default";
import { initGmapsField } from "../../form-collections";
import { ts } from "../../common";
import AddressManager from "../sections/AddressManager";
import AutocompleteField from "./AutocompleteField";

export default class AddressField extends DefaultField {

  static fieldType = "addressField";

  fields = {};



  get hasEmailField()
  {
    return this.config.type === AddressManager.TYPE_PHARMACIES || this.config.type === AddressManager.TYPE_LABORATORIES;
  }

  get hasTypeField()
  {
    return this.config.type === AddressManager.TYPE_ADDRESS;
  }


  getHtml() {

    this.fields.search = new DefaultField(`${this.key}.autocomplete`, {
      rawValue: AddressField.stringifyAddress(this.value.rawValue, false),
      label: ts(`medical_records.profile.address.autocomplete`),
    }, {
      fieldProps: {
        placeholder:  ts(`medical_records.profile.address.autocomplete.placeholder`),
      }
    });


    this.fields.address = this.getSubField("address", {
      containerClass: "flex-grow",
      fieldProps: {
        readonly: true,
        class: "route form-control"
      }
    });


    this.fields.addressExtension = this.getSubField("addressExtension");

    this.fields.postalCode = this.getSubField("postalCode", {
      containerClass: "flex-grow",
      fieldProps: {
        readonly: true,
        class: "postal_code form-control"
      }
    });

    this.fields.city = this.getSubField("city", {
      containerClass: "flex-grow",
      fieldProps: {
        readonly: true,
        class: "locality form-control"
      }
    });

    if(this.hasEmailField) {
      this.fields.email = this.getSubField("email", {
        fieldProps: {
          "type" : "email",
        }
      });

      this.fields.phone = this.getSubField("phone", {
        field : "phoneField",
        fieldProps: {
          "type" : "tel",
        }
      },this.value.rawValue?.phone?.number);
    }

    if(this.hasTypeField) {
      this.fields.type = new AutocompleteField(`${this.key}.type`,{
        rawValue: this.value.rawValue?.type,
        label: ts(`medical_records.profile.address.type`),
      },{
        fieldProps: {
          "parser" : "namedEntity",
          "url" : "api_address_types_get_collection",
          "autoload" : true,
          "unique" : true,
          "params" : {
            "canonical" : ["home","second_home"]
          }
        }
      });
    }

    this.fields.latitude = this.getHiddenField("latitude");
    this.fields.country = this.getHiddenField("country");
    this.fields.longitude = this.getHiddenField("longitude");
    this.fields.id = this?.value?.rawValue?.id ? this.getHiddenField("id") : "";

    return (`<div class="address-container ${this.config.containerClass}" 
id="${this.containerId}" data-field="${this.id}" >
                ${this.fields.type ? this.fields.type.getHtml() : ''}
                ${this.fields.search.getHtml()}
                <div class="address-detail flex flex-column" style="display: none;" >
                    ${this.fields.address.getHtml()}
                    ${this.fields.addressExtension.getHtml()}
                    
                    <div class="form-flex-row" >
                        ${this.fields.postalCode.getHtml()}
                        ${this.fields.city.getHtml()}
                    </div>
                    ${this.fields.latitude.getInput()}
                    ${this.fields.longitude.getInput()}
                    ${this.fields.country.getInput()}
                    ${this.fields.id ? this.fields.id.getInput() : ""}
                   ${this.fields.email ? this.fields.email.getHtml() :""}
                   ${this.fields.phone ? this.fields.phone.getHtml() :""}
                </div>
                </div>`);

  }


  registerEvents() {
    super.registerEvents();

    initGmapsField($(this.$template.find('[id$="autocomplete"]').get(0)));

    this.$template.find('[readonly]').closest('.form-group').hide();
    this.$template.find('input[name*=addressExtension]').closest('.form-group').toggle(!!this.value.rawValue);
    this.$template.find('.address-detail').show();

    if(this.fields.phone) {
      this.fields.phone.registerEvents();
    }

    if(this.fields.type) {
      this.fields.type.registerEvents();
    }


  }


  getHiddenField(key) {
    return this.getSubField(key, {
      fieldProps: {
        type: "hidden",
        class: key
      }
    });
  }

  getSubField(key, config,value) {

    config = config ?? {};

    return new DefaultField(`${this.key}.${key}`, {
      rawValue: value ? value : (this.value?.rawValue ? this.value?.rawValue[key] : ""),
      label: ts(`medical_records.profile.address.${key}`)
    }, { ...this.config, ...config });


  }


  static stringifyAddress(address, multiligne = true) {
    if (!address || !address.address) {
      return "";
    }

    const value = [
      address.address + (address.addressExtension ? "" : ","),
      address.addressExtension ? address.addressExtension + "," : "",
      address.postalCode + " " + (address.fullCountry ? address.city + ", " + address.fullCountry : address.city),

    ].filter((v) => !!v);

    return value.join(multiligne ? "<br/>" : " ").replace(/ +/," ").trim();
  }

}
