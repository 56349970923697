import { buildId, displaySuccess, getFormValue, handleViolations, PUT } from "../../common";
import PatientUpdater from "../patient-updater";
import EditableSectionManager from "./EditableSectionManager";
import { DOCUMENTS_HELPER_MODIFICATIONS_SAVED, trans } from "../../../../translator";

export default class PatientProfileEditableSectionManager extends EditableSectionManager {

  updater;

  violationsMapping = {};

  formPrefix = "profile";


  async init() {
    if (this.isInit) {
      return;
    }

    await super.init();
    this.initUpdater();
  }


  initUpdater() {

    this.updater = new PatientUpdater();
    this.updater.$form = this.$form;

    this.updater.save = async (profile) => {
      return await this.save(profile);
    };

    this.updater.handleBadRequest = (e) => {
      handleViolations(e, this.formPrefix, this.$form, this.violationsMapping, true);
    };


    this.updater.getFormData = async () => {

      const value = this.getFormValue();

      // Field Manager
      if (!value.profile && !value.user) {
        return {
          fields: value.field
        };
      }

      return await this.updater.parseFormData({
        ...value.profile,
        user: {
          id: buildId("users", this.profile.user.id),
          ...value.user
        },
        fields: value.field
      });
    };


    this.updater.onRedirect = (profile) => {

      if (profile) {
        this.profile = { ...this.profile, ...profile };
      }

      this.insertTemplate();

      this.onUpdate();

      displaySuccess(trans(DOCUMENTS_HELPER_MODIFICATIONS_SAVED, {}, 'helper'));

      this.closeDrawer();

    };

    this.updater.init();
  }


  async save(profile) {

    delete profile.fields;

    const data = await PUT(Routing.generate("api_patients_put_item", { id: this.profile.id }), profile);

    this.dispatchEvent(new CustomEvent('save',{
      detail: {
        profile: data
      }
    }));

    return data;

  }


  onSubmit(e) {
    this.updater.onSubmit(e);
  }


  getFormValue() {
    return getFormValue(this.$form, "", { dateFormat: "yy-mm-dd" });
  }

  onUpdate() {

  }


}
