// eslint-disable-next-line no-unused-vars
import tinymce from "tinymce/tinymce";

import intlTelInput from "intl-tel-input";
import { handleTinyMCE } from "./form-utils";
import AddressField from "./patient-profile/fields/AddressField";
import { DOCUMENTS_ACTIONS_ADD, trans, USER_ACTIONS_ADD_ELEMENT, USER_ERROR_ADDRESS_NOT_FOUND } from "../../translator";

/**
 *
 * @param htmlstring
 * @return {ChildNode}
 */
function createElementFromHtmlString(htmlstring) {
  var template = document.createElement('template');
  template.innerHTML = htmlstring;
  return template.content ? template.content.firstChild : template;
}

const isLiProfilUserPage = (li) => {
  const $ul = li.closest('ul');
  if ($ul && $ul.length && $ul.hasClass('phoneProfil')) {
    return true;
  }

  return li.closest('.removable-blocks').length > 0;
};


function CollectionHandler(listElement) {


  let isQuestionnaireForm = listElement.closest('form').attr('name') === "app_questionnaire_questionnaire";

  this.list = listElement;
  this.listTitle = null;
  this.newLinkLi = null;

  this.removeLinkProto = `<button class="remove-link btn btn-danger btn-xs pull-right"> 
<span class="fa fa-times"></button>`;
  this.removeLinkV2Phone = `<span class="remove-link pull-right material-icons">cancel</span>`;

  this.downLinkProto = `<button 
class="down btn btn-primary btn-xs pull-right"> <span class="fa fa-arrow-alt-down"></button>`;
  this.upLinkProto = `<button
 class="up btn btn-primary btn-xs pull-right"> <span class="fa fa-arrow-alt-up"></button>`;
  this.newLinneLink = `<button 
class="new_line btn btn-success btn-xs pull-right" 
title="` + trans(USER_ACTIONS_ADD_ELEMENT, {}, 'user') + `" > <span class="fa fa-plus"></button>`;


  // Create a button to let user add new element Form
  this.createAddButton = function(listEl) {
    const addButtonEl = isLiProfilUserPage(listEl)
      ? $(`
        <button type="button" class="add_link btn btn-light btn-sm mb-1">
          <span class="material-icons">add_circle</span>
          <span class="text">Ajouter ${this.listTitle}</span>
        </button>
      `)
      : $('<button type="button" class="add_link btn btn-primary btn-sm mb-1">' +
        trans(DOCUMENTS_ACTIONS_ADD, { '$title': this.listTitle }, 'documents') + '</button>');

    this.newLinkLi = $('<li class="text-right"></li>').append(addButtonEl);
    listEl.append(this.newLinkLi);

    var that = this;
    addButtonEl.on('click', function(e) {
      e.preventDefault();
      e.stopPropagation();
      that.addForm();
    });
  };
  // Create a button to let user remove an element Form
  this.addRemoveLink = function(liElement) {
    var removePrototype = isLiProfilUserPage(liElement) ? this.removeLinkV2Phone : this.removeLinkProto;

    var newRemoveLink = createElementFromHtmlString(removePrototype);
    liElement.prepend(newRemoveLink);

    liElement.on('click', '.remove-link', function(e) {
      e.preventDefault();
      e.stopPropagation();
      $(this).closest('.multifieldElement').fadeOut().remove();
    });
  };


  this.addForm = function() {
    var prototype = this.list.data('prototype');
    var index = this.list.data('index');
    var newForm = prototype;
    newForm = newForm.replace(/__name__/g, index);
    this.list.data('index', index + 1);
    var newFormLi = $('<li class="multifieldElement"></li>').append(newForm);
    this.newLinkLi.before(newFormLi);
    this.addRemoveLink(newFormLi);
    if (isQuestionnaireForm) {
      this.addUpAndDownLink(newFormLi);
      this.addNewLineLink(newFormLi);
    }
    this.handleAnswers();
    this.handlePhoneNumbers();
    this.handleTinyMCE(newFormLi);

    newFormLi.find('.form-collection-list:not(.form-subcollection-list)').each(function() {
      new CollectionHandler($(this));
    });


    initGmapsAutocomplete();

    return newFormLi;
  };

  this.addUpAndDownLink = (liElement) => {
    let upLinkProto = createElementFromHtmlString(this.upLinkProto);
    let downLinkProto = createElementFromHtmlString(this.downLinkProto);
    liElement.prepend(upLinkProto);
    liElement.prepend(downLinkProto);

    liElement.on('click', '.up', (e) => {

      e.preventDefault();

      let prev = liElement.prev('.multifieldElement');

      if (!prev.length) {
        return;
      }

      this.switchTwoElements(prev, liElement);


    });

    liElement.on('click', '.down', (e) => {

      e.preventDefault();

      let next = liElement.next('.multifieldElement');

      if (!next.length) {
        return;
      }

      this.switchTwoElements(liElement, next);

    });
  };


  this.addNewLineLink = (liElement) => {
    let newLinneLink = createElementFromHtmlString(this.newLinneLink);
    liElement.prepend(newLinneLink);

    liElement.on('click', '.new_line', (e) => {

      e.preventDefault();

      let newElem = this.addForm();

      let next = liElement.next('.multifieldElement');

      let nextId = this.getElementId(next);

      // Don't move if the element is already the next one
      while (nextId !== this.getElementId(newElem)) {

        let _prev = newElem.prev('.multifieldElement');
        this.switchTwoElements(_prev, newElem);

      }


    });

  };

  /**
     *
     * @param a
     * @param b
     */
  this.switchTwoElements = (a, b) => {

    let id = this.getElementId(a);

    let newId = id + 1;

    a.find('[name$="[position]"]').val(newId);
    b.find('[name$="[position]"]').val(id);
    b.insertBefore(a);
  };


  /**
     *
     * @param a
     * @return {*}
     */
  this.getElementId = (a) => {

    let id = parseInt(a.find('[name$="[position]"]').val());

    if (isNaN(id)) {
      id = this.list.length;
    }

    return id;

  };


  this.init = function() {
    var listTitle = this.list.attr('data-title');
    if (!listTitle) {
      listTitle = 'Objet';
    }
    this.listTitle = listTitle;
    this.list.data('index', this.list.find('li').length);

    var that = this;
    this.list.children('li').each(function() {
      that.addRemoveLink($(this));
      if (isQuestionnaireForm) {
        that.addUpAndDownLink($(this));
        that.addNewLineLink($(this));
      }
    });


    this.createAddButton(this.list);


    this.list.find('.form-subcollection-list').each(function() {
      new CollectionHandler($(this));
    });


    this.handleAnswers = function() {
      $(document).find('.answerType').each(manageConditionalFields);
    };

    this.handlePhoneNumbers = () => {
      this.list.find('input[type="tel"]').each((k, elem) => {
        let $elem = $(elem);

        if (!$elem.prop('intlTelInput')) {
          let tel = intlTelInput(elem, {
            initialCountry: "FR",
            utilsScript: "/build/libs/utils.min.js",
          });

          $elem.prop('intlTelInput', tel);
        }
      });
    };

    this.handleTinyMCE = (form) => {
      handleTinyMCE(form);
    };

    that.handleAnswers();

    $(document).on('change', '.answerType', manageConditionalFields);

    this.list.find('[name$="[position]"]').each((k, elem) => {
      $(elem).val(k);
    });

  };


  this.init();
}

jQuery(document).ready(function() {
  $('.form-collection-list:not(.form-subcollection-list)').each(function() {
    new CollectionHandler($(this));
  });
});


function manageConditionalFields() {

  let parent = $(this).closest('div[id]');

  let val = $(this).val();

  parent.find('.conditional').closest('.form-group').hide();
  parent.find('.h-conditional').closest('.form-group').show();

  parent.find('.display_' + val).closest('.form-group').show();
  parent.find('.hide_' + val).closest('.form-group').hide();

}

jQuery(document).ready(function() {
  $('.see-more').on('click', function() {

    let elem = $(this).parent().find('.free-text');

    if ($(elem).is(':visible')) {
      elem.fadeOut(200);
      $(this).html('+');
    } else {
      elem.fadeIn(200);
      $(this).html('-');
    }
  });


  $('.free-text').each((k, e) => {

    let $e = $(e);


    // Display the data inside the fields already filled
    if ($e.val()) {

      let $link = $e.closest('div[id^="app_questionnaire"]').find('.see-more');
      $link.click();
    }

  });

  initGmapsAutocomplete();

});


const getAddressInputContainer = (fields) => {

  // Used on profile
  let inputContainers = fields.closest('.v2_grid_form');

  if (inputContainers.length !== 0) {
    return inputContainers;
  }

  return fields.closest('.form-group');

};


export const initGmapsField = ($elem, enableNotFound = true, onChange) => {

  const componentForm = {
    street_number: "short_name",
    route: "long_name",
    locality: "long_name",
    country: "short_name",
    postal_code: "short_name",
  };


  if ($elem.attr('init')) {
    return;
  }

  let initialValue = $elem.val();

  let label = $elem.attr('gmaps_link_label');

  if (!label) {
    label = trans(USER_ERROR_ADDRESS_NOT_FOUND, {}, 'user');
  }

  let container = $elem.closest('div[id*="address"],li');

  if (container.length === 0) {
    container = $elem.closest(".form-row");
  }

  if (container.length === 0) {
    container = $elem.closest(".address-container");
  }

  const readonlyFields = container.find('*[readonly]');
  const nonReadonlyFields = container.find('*:not(:read-only)');

  let $notFoundLink = $(`<a class="small not-found-link" href="javascript:">${label}</a>`);

  $elem.parent().find('.not-found-link').remove();

  $elem.after($notFoundLink);

  $elem.attr('init', true);

  $notFoundLink.hide();

  getAddressInputContainer(readonlyFields).hide();

  $notFoundLink.on('click', () => {

    let elemContainer = $elem.closest(".autocomplete-field-container");

    if (elemContainer.length === 0) {
      elemContainer = $elem.closest(".row");
    }


    if (elemContainer.length === 0) {
      elemContainer = $elem.closest('.form-group');
    }

    elemContainer.hide();

    container.show();
    container.find('.address-detail').show();

    getAddressInputContainer(readonlyFields).show();

    container.find('input,select').removeAttr('readonly');
    container.find('input,select').removeAttr('readonly');

    const addressInput = container.find('[name$="[address]"]');

    if (!addressInput.val()) {
      addressInput.val($elem.val());
    }

  });

  const containerId = $('.pac-container').length + 1;

  let autocomplete = new google.maps.places.Autocomplete($elem.get(0));

  const interval = setInterval(() => {

    const gmapsContainer = $('.pac-container');

    $notFoundLink.prop('gmaps-interval', interval);

    if (gmapsContainer.length >= containerId) {

      const ctn = $(gmapsContainer.get(containerId - 1));

      if (ctn) {
        ctn.attr('id', 'gmaps-container-' + containerId);
      }
      clearInterval(interval);
    }

  }, 30);


  const _displayAddressField = () => {

    $notFoundLink.hide();
    clearInterval($notFoundLink.prop('interval'));

    setTimeout(() => {
      $notFoundLink.toggle($elem.val() !== initialValue && $elem.val() !== "" &&
          container.find('.pac-item').length === 0);

    }, 1000);

  };

  $elem.on('click', _displayAddressField);

  $elem.on('keyup', _displayAddressField);

  $('body').on('click', (e) => {

    const target = $(e.target);

    if (target.hasClass('not-found-link') || target.hasClass('search-address')) {
      return;
    }

    $('.not-found-link').hide();

  });

  if (!enableNotFound) {
    $notFoundLink.remove();
  }


  autocomplete.addListener("place_changed", function() {

    initialValue = $elem.val();
    $notFoundLink.hide();
    container.show();
    container.find('.address-detail').show();

    getAddressInputContainer(nonReadonlyFields).show();

    getAddressInputContainer(readonlyFields).hide();
    readonlyFields.removeAttr('required');

    // autocomplete.setFields(["address_component","establishment"]);
    const place = autocomplete.getPlace();

    let fullAddress = "";

    for (const component of Object.keys(componentForm)) {
      if (component === "street_number") {
        continue;
      }

      container.find(`.${component}`).val('');
    }

    if (place.geometry && place.geometry.location) {
      container.find('.latitude').val(place.geometry.location.lat());
      container.find('.longitude').val(place.geometry.location.lng());
    }


    if (place.formatted_address.indexOf(place.name) === -1) {
      fullAddress += place.name + ", ";
    }

    if (place.address_components) {
      for (const component of place.address_components) {
        const addressType = component.types[0];

        if (addressType === "street_number") {
          fullAddress = fullAddress + component[componentForm[addressType]];
          continue;
        }

        if (componentForm[addressType]) {

          let val;

          if (addressType === "route") {
            val = fullAddress + ' ' + component[componentForm[addressType]];
          } else {
            val = component[componentForm[addressType]];
          }

          if (addressType === "country") {
            $('.country option[value="' + val + '"]').attr('selected', 'selected');
          }
          container.find(`.${addressType}`).val(val);
        }
      }
    }

    // Fix bug for "chateau de beaulieu 61340 Perche-en-Nocé"
    if(!container.find('.route').val() && place.name) {
      container.find('.route').val(place.name);
    }

    onChange && onChange(place);

    const obj = AddressField.stringifyAddress({
      address: container.find('.route').val(),
      postalCode: container.find('.postal_code').val(),
      city: container.find('.locality').val(),
    },false);

    $elem.val(obj);

  });
};


function initGmapsAutocomplete() {

  var addresses = $(".search-address");

  if (('form[name="app_personnal_addresses"]').length > 0) {
    $('form[name="app_personnal_addresses"]').on('keydown', function(e) {
      var keyCode = e.keyCode || e.which;
      if (keyCode === 13) {
        e.preventDefault();
      }
    });
  }

  addresses.each((index, elem) => {
    initGmapsField($(elem));
  });

}
