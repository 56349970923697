import BaseRepeaterField from "./BaseRepeaterField";
import AbstractFieldRowDataManager from "../sections/AbstractFieldRowDataManager";

export default class RepeaterField extends BaseRepeaterField {

  static fieldType = "repeaterField";

  children;

  /**
     *
     * @type {DefaultField[]}
     */
  formFields = [];

  constructor(key, value, config, manager) {
    super(key, value, config, manager);

    this.children = config.children;

    this.value = value.value;

  }


  getSubFields(val, index) {

    const fieldData = this.children[index] ?? this.children[0];

    const fields = [];
    Object.keys(fieldData).forEach((key) => {
      const field = fieldData[key];

      const value = this.value[index] ? this.value[index][key] : {
        label : field.label
      };

      fields.push(this.getSubField(key, value,index, field));

    });

    return fields.join("");

  }


  getSubField(key, value,index, config) {

    key = `${this.key}.${index}.${key}`;

    this.formFields[index] = this.formFields[index] ?? [];

    this.formFields[index][key] = AbstractFieldRowDataManager.buildField(key,value,config,this);

    return this.formFields[index][key].getHtml();

  }

  addRow(val = {}, index) {

    if(undefined === index) {
      index = this.index;
    }

    super.addRow(val, index);

    Object.keys(this.formFields[index]).forEach((key) => {
      const field = this.formFields[index][key];

      field.init();
      field.registerEvents();

      field.addEventListener('change', (e) => {

        this.onChange(e);
      });

    });

  }


  update(data,value) {

    this.children = data.children;
    this.index = 0;
    this.value = value.value;

    this.init();

  }



}
