import Select from "./select";

export default class ConstantSelect extends Select {

  static fieldType = "constantSelect";

  constanteKey;

  constructor(key, value, config = {}, manager) {
    super(key,value,config,manager);

    if(config.constanteKey) {
      this.constanteKey = config.constanteKey;
    }

  }


  getInput() {

    this.initOptions();
    return super.getInput();

  }

  initOptions()
  {

    const constantData = this.manager.getConstantData(this.constanteKey ?? this.key);

    this.options = {};

    this.placeholder = constantData.placeholder;

    Object.keys(constantData.data).forEach((key) => {
      this.options[key] = constantData.data[key];
    });

    if(this.customValueLabel) {
      this.options[this.customValueLabel] = this.customValueLabel;
    }

    this.optionsOrder = Object.keys(this.options);

  }



}
