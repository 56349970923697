import DefaultField from "./default";
import DocumentUploader from "../../documents/document-uploader";
import { DOCUMENTS_ACTIONS_SEE_DOC, trans } from "../../../../translator";

export default class FileInput extends DefaultField {


  static fieldType = 'fileField';

  constructor(attributes, manager, value, config) {
    super(attributes,manager,value,config);

    this.attributes.type = "file";

  }

  get fieldValue()
  {
    const $input = this.$template.find('input');

    return $input.files;

  }

  getInput() {
    const $input = `<input ${this.getAttributes()} />`;

    const document = this.value.rawValue;

    if(document && document['@id']) {
      const $link = (`<a class="file-link block" href="${document.fileUrl}" >` +
        trans(DOCUMENTS_ACTIONS_SEE_DOC, {}, 'documents') + `</a>`);

      return $input + $link;
    }

    return $input;
  }

  registerEvents() {
    super.registerEvents();

    const document = this.value.rawValue;

    if(document) {
      this.$template.find('.file-link').off('click').on('click', (e) => {
        e.preventDefault();
        DocumentUploader.openDocument(document);
      });
    }

  }


}
