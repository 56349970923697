import DefaultField from "./default";

export default class BaseRepeaterField extends DefaultField {

  index = 0;
  button;

  allowEmpty;

  constructor(key, value, config, manager) {
    super(key, value, config, manager);

    this.label = config.label;

    const props = (config?.fieldProps ?? {});

    this.button = config.button;

    this.allowEmpty = props.allowEmpty ?? true;

  }


  init() {

    this.$template.find('.field-list').html('');

    if (this.value.length) {
      this.value.forEach((val, index) => {
        this.addRow(val.rawValue, index);
      });
    } else {
      this.addRow({}, this.index);
    }
  }


  getHtml() {

    return `
<input type="hidden" name="${this.name}" >
${this.getTitle()}
${this.getLabel({ label: this.label })}
<div class="form-group multi-field relative" data-field="${this.id}" >
             <div class="field-list" ></div>
         ${this.addButton(this.button)}
         </div>
         `;

  }


  registerEvents() {

    this.$template.find('.add').off('click').on('click', () => {
      this.addRow();
    });

  }


  addRow(val = {}, index) {

    if (undefined === index) {
      index = this.index;
    }

    const row = this.getRow(val, index);

    row.addClass('repeater-row');

    this.$template.find('.field-list').append(row);
    this.index++;

    row.find('.remove-link').off('click').on('click', (e) => {
      $(e.target).closest('.repeater-row').remove();
      delete this.value[index];

      if (this.$template.find('.repeater-row').length === 0) {
        this.index = 0;
        if (!this.allowEmpty) {
          this.addRow();
        }
      }
    });

    this.registerEvents();
  }


  getRow(val, index) {
    return $(`<div class="form-flex-row" >
            <div class="repeater-row-fields-container" >
                ${this.getSubFields(val, index)}
            </div>           
           ${this.getDeleteButton()}
        </div>`);
  }


  getDeleteButton() {
    return `<span class="remove-link pull-right material-icons">cancel</span>`;
  }


  getSubField(value, index, name, label, attributes) {

    attributes = {
      ...attributes, ...{
        id: `${this.id}_${index}_${name}`,
        name: `${this.name}[${index}][${name}]`,
      }
    };

    const input = `<input ${this.getAttributes(value[name], attributes)} >`;

    if (attributes.type === "hidden") {
      return input;
    }

    return `<div class="flex-col flex-grow-1">
            ${this.getLabel({ label: label }, attributes)}
            ${input}
        </div>`;
  }


  addButton(text) {
    return `<button type="button" class="add btn btn-light btn-sm mb-1">
            <span class="material-icons">add_circle</span>
            <span class="text">${text}</span>
        </button>`;
  }


}
