import { addScrollButton, closeDrawer, openDrawer } from '../../rightPanel';
import SectionManager from './SectionManager';

export default class EditableSectionManager extends SectionManager {

  $drawer = $('#edit-profile');

  $form = this.$drawer.find('form');

  $loader = this.$drawer.find('.loader');

  async init() {
    if (this.isInit) {
      return;
    }

    await super.init();
    this.registerEvents();
  }

  registerEvents() {

    this.$template.find('.update_button').attr('id', `update_${this.section.canonical}`);

    this.$template.find('.update_button').off('click').on('click', async () => {
      if(!this.profile.user.rights.update) {
        $('#upgrade-popin').show();
      } else {
        await this.openDrawer();
      }
    });

    this.$drawer.find('.cancel').off('click').on('click', (e) => {
      e.preventDefault();
      this.closeDrawer();
    });

    this.$drawer.find('.close-box').off('click').on('click', () => {
      this.closeDrawer();
    });

    super.registerEvents();
  }

  fillForm() {
    this.$drawer.find('.fields').scrollTop(0);
    this.$drawer.find('.fields').html('');
    this.$drawer.find('.drawer-header h2').html(this.section.name);
  }

  async openDrawer() {

    if(!this.profile.user.rights.update) {
      $('#upgrade-popin').show();
      return;
    }

    await this.fillForm();

    openDrawer(this.$drawer);

    this.onOpen();

    addScrollButton(this.$drawer.find('.fields'));

    this.$form.off('submit').on('submit', (e) => {
      this.onSubmit(e);
    });
  }

  onOpen() {}

  onClose() {}

  onSubmit(e) {
    e.preventDefault();
  }

  closeDrawer() {
    this.onClose();
    this.$loader.hide();
    closeDrawer(this.$drawer);
  }

  getConstantData(key) {
    let [object, field] = EditableSectionManager.splitKey(key);

    if (object === 'profile') {
      object = 'patient';
    }

    return this.manager.constants[object].properties[field];
  }

  static splitKey(key) {
    return key.split('.');
  }

}
