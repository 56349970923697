import DefaultField from "./default";
import TagsAutocompleter from "../../TagsAutocompleter";

export default class AutocompleteField extends DefaultField {

  static fieldType = "autocompleteField";

  autocompletor;

  get defaultAttributes() {
    return {
      ...super.defaultAttributes,
      pretty: true
    };
  }

  get availableAttributes() {
    return [
      ...super.availableAttributes,
      "multiple", "url", "parser", "autoload", "params"
    ];
  }

  getHtml() {
    return (`<div class="form-group relative v2-autocomplete" data-field="${this.id}" id="${this.containerId}">
        ${this.getTitle()}
        ${this.getLabel(this.value)}
        ${this.getHelpText()}
        ${this.getInput()}
        ${this.getSuffix()}
        </div>`
    );
  }


  async registerEvents() {

    this.autocompletor = new TagsAutocompleter(this.$template.find('input'));

    if (this.config?.fieldProps?.params) {
      this.autocompletor.params = this.config?.fieldProps?.params;
    }

    this.autocompletor.asPortal = true;

    if (this.hasOptions()) {
      this.autocompletor.choices = this.options;
    }

    await this.autocompletor.init();
    this.autocompletor.clear();

    if (Array.isArray(this.value.rawValue)) {
      this.value.rawValue.forEach((item) => {
        const value = this.autocompletor.data.find((data) => {

          if(typeof item === "object" && typeof data === "object") {
            if(item.id && data.id) {
              return item.id === data.id;
            }
          }
          return data.value.toString() === item.toString();});

        this.addValue(value ?? item);
      });

    } else if (typeof this.value.rawValue === "object") {
      this.addValue(this.value.rawValue);
    }

    this.autocompletor.addEventListener('itemAdded',(e) => {
      this.onChange(e);
    });
    this.autocompletor.addEventListener('itemRemoved',(e) => {
      this.onChange(e);
    });

  }


  addValue(item) {
    if (typeof item === "string" && this.options[item]) {
      item = {
        id: item,
        name: this.options[item]
      };
    }

    if (!item) {
      return;
    }


    if (typeof item === "string") {
      this.autocompletor.addTag(item, item);
      return;
    }

    // Specific case when options are loaded from back-end
    if(item.value && item.label) {
      this.autocompletor.addTag(item.value.toString(), item.label, item);
      return;
    }

    if (item.id) {

      const isReadOnly =
        this.config.fieldProps.readOnlyValues &&
        this.config.fieldProps.readOnlyValues.includes(item.id);

      if (isReadOnly) {
        this.autocompletor.addTag(item.id.toString(), item.name, item, true);
      } else {
        this.autocompletor.addTag(item.id.toString(), item.name, item);
      }
    }

  }


  hasOptions() {
    if (!this.options) {
      return false;
    }

    if (Array.isArray(this.options)) {
      return this.options.length !== 0;
    }

    return Object.keys(this.options).length !== 0;

  }


}
