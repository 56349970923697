import Select from "./select";

export default class RadioField extends Select {

  static fieldType = "radioField";

  inputType;

  get originalFieldValue() {
    if (this.inputType === "radio") {
      return this.$template.find('input:checked').val() ?? null;
    } else {
      return super.originalFieldValue;
    }
  }


  set originalFieldValue(value) {
    if (this.inputType === "radio") {
      this.$template.find(`input[value="${value}"]`).prop("checked", true);
    } else {
      super.originalFieldValue = value;
    }
  }

  getSuffix() {
    return "";
  }


  getInput() {

    const inline = this.isInline();

    this.isCustomValue = !!this.customValueLabel;

    this.inputType = inline ? "radio" : "select";

    if (!inline) {
      return super.getInput();
    }

    return `
            ${this.getInlineInput()}
            ${this.getCustomValueField(this.isCustomValue ? this.value?.rawValue : "")}
        `;
  }


  getInlineInput() {

    const id = this.id;

    return `<div class="radio-values">${this.optionsOrder.map((key) => {
      const value = this.options[key];


      this.id = `${id}_${key}`.replace(/[^a-zA-Z0-9-_]/g, "_");
      
      let attributes = { type: "radio" };

      if (this.value?.rawValue?.toString() === key.toString()) {
        this.isCustomValue = false;
        attributes.checked = true;
      }

      if (!this.value?.rawValue) {
        this.isCustomValue = false;
      }

      if (this.isCustomValue && key.toString() === this.customValueLabel) {
        attributes.checked = true;
      }


      const labelClass = this.getLabelClass(value ?? key, true);

      return `<div>
                    <input ${this.getAttributes(key, attributes)} />
                    <label class="${labelClass}" for="${this.id}">${value ?? key}</label>
                </div>`;
    }).join("")}
        </div>`;

  }


  addError(message) {
    if (this.inputType === "select") {
      super.addError(message);
      return;
    }

    this.$template.append(`<span class="form-error">${message}</span>`);
    this.$template.find('input:checked').addClass('with-error');

  }


  isInline() {

    let nbItem = 0;
    let textlength = 0;

    Object.keys(this.options).map((key) => {
      nbItem++;
      const value = this.options[key] ?? key;
      textlength += value.toString().length;
    });

    return (textlength + (nbItem * 5)) <= 70;

  }


  getLabelClass(value, inline) {

    if (!inline) {
      return "text-left";
    }

    if (!value) {
      return "text-center";
    }

    if (value.toString().length <= 3) {
      return "text-center";
    }

    return "text-left";

  }


  registerEvents() {
    super.registerEvents();

    if (this.inputType === "select") {
      return;
    }

    this.$template.find('input[type="radio"]').prop('field', this);

    this.$template.find('label').on('click', (e) => {

      const label = $(e.target);

      const id = label.attr('for');

      const input = this.$template.find(`#${id}`);

      if (input.prop("checked")) {
        input.prop('checked', false);
        this.onChange(e);
        e.preventDefault();
      }
    });
  }


}
