import DefaultField from "./default";

export default class Select extends DefaultField {

  static fieldType = "select";

  optionsOrder;

  placeholder;

  isCustomValue;

  _customValueLabel;


  set customValueLabel(value) {
    this._customValueLabel = value;
    this.initCustomValueLabel();
  }

  get customValueLabel() {
    return this._customValueLabel;
  }


  constructor(key, value, config = {}, manager) {

    super(key, value, config, manager);

    if (config?.placeholder) {
      this.placeholder = config.placeholder;
    }

    if (!this.options) {
      this.options = {};
    }
    this.optionsOrder = config.optionsOrder &&
    config.optionsOrder.length ? config.optionsOrder :
      Object.keys(this.options);

    this.customValueLabel = config.customValueLabel;

  }


  initCustomValueLabel() {

    if (!this.customValueLabel || this.options[this.customValueLabel]) {
      return;
    }

    this.options[this.customValueLabel] = this.customValueLabel;

    if(!this.optionsOrder.includes(this.customValueLabel)) {
      this.optionsOrder.push(this.customValueLabel);
    }
  }

  getInput() {
    return `<select ${this.getAttributes()}>
        ${this.getPlaceholder()}
        ${this.getOptions()}
        </select>
        ${this.getCustomValueField(this.isCustomValue ? this.value?.rawValue : "")}
`;
  }


  getOptions() {

    this.isCustomValue = !!this.customValueLabel;

    return this.optionsOrder.map((key) => {
      const value = this.options[key];

      let selected = false;

      if (this.value?.rawValue?.toString() === key.toString()) {
        this.isCustomValue = false;
        selected = true;
      }

      if (!this.value?.rawValue) {
        this.isCustomValue = false;
      }

      if (this.isCustomValue && key.toString() === this.customValueLabel) {
        selected = true;
      }

      return `<option ${selected ? 'selected' : ''} value="${key}" >${value}</option>`;
    }).join("");

  }


  getPlaceholder() {

    if (!this.placeholder) {
      return "";
    }

    return `<option value="" >${this.placeholder}</option>`;
  }

  get originalFieldValue() {
    return this.$template.find('select').val();
  }

  set originalFieldValue(value) {
    this.$template.find('select').val(value);
  }


  addError(message) {
    this.$template.find('select').after(`<span class="form-error">${message}</span>`);
    this.$template.find('select').addClass('with-error');
  }



  get fieldValue() {
    if (this.originalFieldValue !== this.customValueLabel) {
      return this.originalFieldValue;
    }

    const customValue = this.$template.find('.custom-value-field').val();

    if(customValue) {
      return customValue;
    }

    this.doToggleCustomValueField(false);

    setTimeout(() => {
      this.doToggleCustomValueField(true);
    },10);


    return this.originalFieldValue;

  }

  registerEvents() {
    this.$template.find('select').prop('field', this);
    super.registerEvents();

    this.toggleCustomValueField();
  }

  update(config, value) {

    const isCustomValue = this.originalFieldValue === config.customValueLabel;

    this.value = value;

    // Setting the original value
    if(isCustomValue) {
      this.originalFieldValue = config.customValueLabel;
    }

    this.options = config.options;
    if(!this.optionsOrder || !this.optionsOrder.length) {
      this.optionsOrder = Object.keys(this.options);
    }

    this.customValueLabel = config.customValueLabel;

    // I removed this, else the custom value field is removed and not added again, I don't know why
    // I'm pretty sure it's a bad idea, and it'll break something else
    this.$template.find('.custom-value-field').remove();

    if(this.$template.find('select').length) {
      const $html = $(this.getHtml());
      this.$template.replaceWith($html);
      this.$template = $html;
    } else {
      this.$template.find('.radio-values').replaceWith(this.getInput());
    }

    this.registerEvents();

    this.toggleCustomValueField();

  }


  onChange(e) {
    this.toggleCustomValueField();
    super.onChange(e);
  }

  toggleCustomValueField() {

    const toggle = this.originalFieldValue === this.customValueLabel;

    this.doToggleCustomValueField(toggle);
  }

  doToggleCustomValueField(toggle) {


    const $input = this.$template.find('.custom-value-field');

    $input.toggle(toggle);

    if (toggle) {
      $input.attr("name", this.name);

      if (this.required) {
        $input.attr("required", true);
      }

    } else {
      $input.removeAttr("name");
      $input.removeAttr("required");
    }

  }


}
