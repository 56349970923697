import { fillField, initInputFormat, initTelInput } from "../../form-utils";
import { uniqid } from "../../common";

export default class DefaultField extends EventTarget {

  attributes;

  _containerId;


  set disabled(disabled) {
    this.$template.find('input,select,textarea').prop('disabled', disabled);
  }

  get disabled() {
    return this.$template.find('input,select,textarea').prop('disabled');
  }


  get containerId() {
    if (!this._containerId) {
      this._containerId = uniqid("field");
    }

    return this._containerId;

  }


  init() {
    this.$template.find('input').prop('inputObject', this);
  }


  get defaultAttributes() {
    return {
      "class": "form-control"
    };
  }


  get availableAttributes() {
    return ["required", "step", "min", "max", "unique", "allow_txt", "format", "readonly", "class", "placeholder"];
  }

  // This function returns the original value of the input
  get originalFieldValue() {
    return this.$template.find('input').val();
  }

  // In some cases, the value of the field is custom, so this function helps getting the real value
  get fieldValue() {
    return this.originalFieldValue;
  }

  required;
  dynamic;

  value;
  config;

  name;
  id;

  manager;

  _template;

  get $template() {
    if (!this._template) {
      this._template = $(`#${this.containerId}`);
    }
    return this._template;
  }

  set $template(template) {
    this._template = template;
  }


  constructor(key, value, config = {}, manager) {

    super();

    this.key = key;
    this.manager = manager;
    this.value = value;
    this.config = config ?? {};
    this.dynamic = config.dynamic ?? false;
    this.options = this.config.options ?? {};

    this.name = this.createName(key);

    this.id = key.replace(/\./g, "_");

    const props = (config?.fieldProps ?? {});

    if (props.label) {
      this.value.label = props.label;
    }

    this.attributes = this.defaultAttributes;

    this.attributes.type = props.type ?? "text";

    this.attributes = { ...this.attributes, ...props };

    this.availableAttributes.forEach((field) => {
      if (props[field] !== undefined) {
        this.attributes[field] = props[field];
      }
    });

  }


  getHtml() {
    return (`<div class="form-group relative ${this.config?.containerClass ?? ""}"
 data-field="${this.id}" id="${this.containerId}" >
        ${this.getTitle()}
        ${this.getLabel(this.value)}
        ${this.getHelpText()}
        ${this.getInput()}
         ${this.getSuffix()}
        </div>`
    );
  }


  getHelpText() {
    const { helpText } = this.config;

    if (!helpText) {
      return "";
    }

    return `<p class='small mg-bottom-5' >${helpText}</p>`;
  }

  getTitle() {
    const { title } = this.config;

    if (!title) {
      return "";
    }

    return `<h4 class="section-title" >${title}</h4>`;
  }


  getInput() {
    const displayedValue = this.value.rawValue === null ? "" : this.value.rawValue;

    return `<input ${this.getAttributes(displayedValue)} />`;
  }


  getLabel(value, attributes = {}) {

    if (!value.label) {
      return ``;
    }

    const requiredAttr = this.attributes.required ? "required" : "";

    return `<label class="${requiredAttr} main-label" for="${attributes.id ?? this.id}">${value.label}</label>`;
  }


  getSuffix() {
    const { suffix } = this.config;

    if (!suffix) {
      return "";
    }

    return `<span class='suffix' >${suffix}</span>`;

  }


  createName(key) {

    let name = key.split('.');

    name = name.map((key, index) => {
      if (index === 0) {
        return key;
      }
      return `[${key}]`;
    });

    return name.join('');

  }


  getAttributes(value, baseAttributes) {

    baseAttributes = baseAttributes ?? {};

    const attributes = {
      ...this.attributes, ...{
        name: this.name,
        id: this.id,
        value: value ?? ""
      }, ...baseAttributes
    };

    let template = "";

    Object.keys(attributes).map((key) => {
      template += `${key}="${attributes[key]}"\n`;
    });

    return template;
  }


  registerEvents() {

    this.$template.find('input,select').off('change').on('change', (e) => {
      this.onChange(e);
    });

    this.$template.find('input').off('keyup').on('keyup', (e) => {
      this.onChange(e);
    });

    if(this.attributes.type === "number") {
      this.$template.find('input').on('wheel',(e) => {
        e.preventDefault();
      });
    }


    if (this.attributes.format) {
      initInputFormat(this.$template.find('input'));
    }

    if (this.attributes.type === "tel") {
      initTelInput(this.$template.find('input'));
    }

  }


  display() {
    this.$template = $(this.getHtml());

    return this.$template;
  }

  addError(message) {
    this.$template.find('input').after(`<span class="form-error">${message}</span>`);
    this.$template.find('input').addClass('with-error');
  }

  resetError() {
    this.$template.find('input').removeClass("with-error");
    this.$template.find('.form-error').remove();
  }

  remove() {
    this.$template.remove();
    delete this;
  }

  onChange(e) {
    this.resetError();

    const event = new CustomEvent('change', {
      detail: {
        realEvent: e,
        isKeyUp: e.type === "keyup" || e.detail?.realEvent?.type === "keyup",
        isDynamicField : e.detail?.isDynamicField ?? this.dynamic
      }
    }
    );

    this.dispatchEvent(event);

  }



  getCustomValueField(value) {
    if (!this.customValueLabel) {
      return "";
    }

    return `<input type="text" ${value ? `value="${value}"` : `style="display: none;"`} 
class="form-control mg-top-10 custom-value-field" placeholder="Votre réponse" >`;

  }


  update(data, value) {
    this.value = value;

    fillField(this.$template.find('input'),this.value.rawValue ?? "");
  }


}
