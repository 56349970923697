import { ts } from "../../common";
import AddressesField from "../fields/AddressesField";
import PatientProfileEditableSectionManager from "./PatientProfileEditableSectionManager";
import { MEDICAL_RECORD_SECTIONS } from "../../utils";
import DataTransformer from "../DataTransformer";

export default class AddressManager extends PatientProfileEditableSectionManager {

  static TYPE_ADDRESS = "addresses";
  static TYPE_PHARMACIES = "pharmacies";
  static TYPE_LABORATORIES = "laboratories";

  addressType;

  fillTemplate() {
    super.fillTemplate();

    const $list = this.$template.find('.v2__list_dot');
    $list.html('');
    const $emptyText = this.$template.find('.empty-text');

    if (this.profile[this.addressType] && this.profile[this.addressType].length) {
      this.profile[this.addressType].forEach((address,index) => {
        const $rowTemplate = this.displayAddress(address, this.addressType,index);
        if($rowTemplate) {
          $list.append($rowTemplate);
        }
      });
      $list.show();
      $emptyText.hide();
    } else {
      $list.hide();
      $emptyText.show();
    }


  }


  getSectionData() {
    return $('#section-address').html();
  }


  displayAddress(address, type,index) {
    const $rowTemplate = $($('#address-item').html());

    const position = index+1;

    if(!address.address && !address.addressExtension && !address.postalCode && !address.city) {
      return;
    }

    $rowTemplate.find('.title').html(address.name ?? (ts(`address.${type}`) + ' ' + position));
    $rowTemplate.find('.address').html(`${address.address ?? ""} ${address.addressExtension ?? ""}`.trim());

    $rowTemplate.find('.city').html(`${address.postalCode ?? ""} ${address.city ?? ""}, ${address.fullCountry ?? ""}`.trim().replace(/,$/,''));

    $rowTemplate.find('.email').append(address.email);

    if(address.phone) {

      let number;

      try {
        number = intlTelInputUtils.formatNumber(
          address.phone.number, null,
          intlTelInputUtils.numberFormat.INTERNATIONAL);
      } catch (e) {
        number = address.phone.nationalNumber;
      }

      $rowTemplate.find('.phone').append(number);
    }

    return $rowTemplate;

  }

  fillForm() {
    super.fillForm();

    const $input = new AddressesField(
      `profile.${this.addressType}`,
      this.getValue(this.profile[this.addressType]),
      {
        type: this.addressType,
        containerClass : "flex-grow",
        fieldProps : {
          allowEmpty : false
        }
      },
      this
    );

    this.$form.find('.fields').html($input.display());

    $input.init();

    $input.registerEvents();

  }


  getValue(addresses) {

    const addressesType = [...addresses];

    return addressesType.map((value) => {
      return DataTransformer.addressTransformer(value, this.key, {});
    });
  }

  getFormValue()
  {
    const value = super.getFormValue();
    // Object to array
    if(value.profile[this.addressType]) {
      value.profile[this.addressType] = Object.keys(value.profile[this.addressType]).map((key) => {

        const object = value.profile[this.addressType][key];
        // Autocompletor to object
        if(object.type && object.type.data) {
          object.type = object.type.data;
        }

        return object;
      });
    }

    return value;

  }


  onUpdate() {
    this.manager.refreshSection(MEDICAL_RECORD_SECTIONS.TYPE.TYPE_GENERAL_DATA);
  }


}
